import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { searchAction } from "../../store/actions/searchActions";
import {
  toggleModalAction,
  changeQtypeAction,
} from "../../store/actions/filtersActions";
import FiltersModal from "../filters/FiltersModal";
import QuerySelection from "./QuerySelection";
import CategorySelection from "./CategorySelection";
import langdata from "../../constants/langdata";
import { LocalizationContext } from "../../localizationContext";
import SortSelection from "./SortSelection";
import { changeSortOrderAction } from "../../store/actions/filtersActions";
import { setSummaryAction } from "../../store/actions/SummaryActions";

class SearchSection extends Component {
  static contextType = LocalizationContext;
  state = {
    search_field: "",
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.entered = this.entered.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
  }

  handleChange(e) {
    this.setState({
      search_field: e.target.value,
    });
  }

  handleSubmit(e) {
    console.log(this.props.filters.tab)
    this.props.performSearch(this.state.search_field, this.props.filters);
    this.props.setSummaryAction(null);
  }

  entered(e) {
    if (e.keyCode === 13) {
      this.handleSubmit(e);
    }
  }

  toggleFilters(e) {
    this.props.toggleModal(!this.props.filters.isOpen);
  }

  render() {
    const { language } = this.context;

    return (
      <div>
        <div className="input-field margin-auto">
          <i className="material-icons prefix">search</i>
          <input
            className="urdu-font arabic-font"
            onKeyDown={this.entered}
            onChange={this.handleChange}
            id="search_field"
            type="text"
          ></input>
          <label htmlFor="search_field">
            {langdata[language]["buttons"]["Search"]}
          </label>
        </div>
        <div id="filters-container" className="container">
          <div className="width-content margin-auto">
            <FiltersModal />
          </div>
        </div>
        <div className="margin-auto width-content margin-top-2">
          <CategorySelection />
        </div>
        <div className="margin-auto width-content margin-top-2">
          <QuerySelection />
        </div>
        <div className="margin-auto width-content margin-top-2">
          <SortSelection />
        </div>
        {this.props.filters.tab !== "all_indices" && (
          <div className="margin-auto width-content margin-top-2">
            <button
              id="adv-filters-btn"
              style={{ backgroundColor: "#0b3557" }}
              onClick={this.toggleFilters}
              className="btn waves-effect waves-light margin-auto"
            >
              {langdata[language]["buttons"]["Advanced Filters"]}
            </button>
          </div>
        )}
        <div className="margin-auto width-content margin-top-2">
          <button
            id="search-btn"
            onClick={this.handleSubmit}
            className="btn waves-effect waves-light margin-auto"
          >
            {langdata[language]["buttons"]["Search"]}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters, //entire state obj
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    performSearch: (term, filters = null) =>
    dispatch(searchAction(term, 0, filters)),
    toggleModal: (value) => dispatch(toggleModalAction(value)),
    changeQtype: (value) => dispatch(changeQtypeAction(value)),
    changeSortOrder: (value) => dispatch(changeSortOrderAction(value)),
    setSummaryAction: (value) => dispatch(setSummaryAction(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
