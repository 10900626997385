import {backendUrl, summaryPath, summaryAccessPath} from '../../backendInfo'
import {addPDFAction} from '../../store/actions/pdfActions';
import MySpinner from '../layout/MySpinner'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import Cookies from 'js-cookie'
import axios from 'axios'
import { setSummaryAction, setSummaryRequestingAction, setSummaryAccessAction } from '../../store/actions/SummaryActions'

class Summary extends Component {
    state = {
        requesting: false,
    };

  

  

    createParams = (term, filters, size) => ({
        term,
        ...filters,
        size: 10
    });

    requestSummary = () => {
    

        this.setState({ requesting: true });
        // Clear existing summary when starting a new request
        this.props.setSummary(null);
        // Set requesting state in Redux
        this.props.setSummaryRequesting(true);
        
        const { term, filters, size } = this.props;
        const access = Cookies.get('token');
        const params = this.createParams(term, filters, size);
    
        axios.get(`${backendUrl}${summaryPath}`, { params, headers: {
            'Authorization': `Bearer ${access}`
        }})
            .then(res => {
                this.props.setSummary(res.data.summaries); // set object directly
                this.setState({ requesting: false });
                this.props.setSummaryRequesting(false);
    
                console.log(this.props.summary)
            })
            .catch(err => {
                console.log(err);
                this.setState({ requesting: false });
                this.props.setSummaryRequesting(false);
            });
    };

    render() {
        const { summary } = this.props;
        const { requesting } = this.state || {};
    
        return (
            <>
            
                <div className="width-content margin-auto">
                <button 
                    className="btn-small black-text waves-effect"
                    onClick={this.requestSummary}
                    disabled={requesting}>
                    Get Summary
                </button>
            </div>
            
            </>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setSummary: summary => dispatch(setSummaryAction(summary)),
    setSummaryRequesting: requesting => dispatch(setSummaryRequestingAction(requesting)),
});

const mapStateToProps = state => ({
    term: state.search.searchTerm,
    filters: state.filters,
    summary: state.summary.summary,
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);