export const backendUrl = "https://backend.minnatulaleem.com" // without trailing slash
//export const backendUrl = "http://localhost:8000" // without trailing slash

export const microsoftLoginPath = "/accounts/microsoft/login/?process=login"
export const googleLoginPath = "/accounts/google/login/?process=login"
export const tokenVerifyPath = "/accounts/token/verify/"
export const logoutPath = "/accounts/logout/"
// querying
export const queryPath = "/api/query/"
export const queryDetailPath = "/api/detail/"
export const querySave='/api/save/'
export const queryUrl = "/api/signedurl/"
export const customtab="/api/customtab/"
export const queryBookmarkPath='/bookmarks/save/'
export const getqueryBookmarkPath='/bookmarks/get/'
export const saveFeedbackPath='/feedback/save/'
export const summaryPath='/api/summary/'
export const summaryAccessPath='/accounts/ai_summary_access/'


// history
export const historyPath = "/api/history/"
// pdf export
export const requestPDFPath = "/export/request/"
export const takeawayPDFPath = "/export/takeaway/"
// filters metadata
export const filtersMetadataPath = "/filters-metadata/"


// Index names
export const ALL_TABS_INDEX= 'all_indices'
export const URDU_BOOKS_INDEX = 'jamati_books_ar_analyzer'
export const ENGLISH_BOOKS_INDEX = 'eng_jamaat_books'
export const ARABIC_BOOKS_INDEX = 'arabic_books'
export const ROR_INDEX = 'review_of_religions'
export const ALFAZAL_INDEX = 'new_alfazal'
export const HOLY_QURAN_INDEX = 'holy_quran_new'
export const ASK_ISLAM_INDEX = 'ask_islam'
export const DARSUL_QURAN_INDEX = 'darsul_quran'
export const ALBADAR_INDEX = 'albadar'
export const ALHAKAM_INDEX = 'alhakam'
export const MUSLIM_SUNRISE_INDEX = 'muslim_sunrise'
export const FRIDAY_SERMON = 'friday_sermon'
export const AK_DIARIES = 'ak_diaries'
export const GERMAN_BOOKS='german_books'
export const HISTORY='history'





