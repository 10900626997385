import axios from 'axios';
import Cookies from 'js-cookie';
import { backendUrl, summaryAccessPath } from '../../backendInfo';

export const SET_SUMMARY_ACCESS = 'SET_SUMMARY_ACCESS';

export const setSummaryAction = (summary) => {
    return { type: 'SET_SUMMARY', payload: summary }
}
export const setSummaryRequestingAction = (requesting) => {
    return {
        type: 'SET_SUMMARY_REQUESTING',
        requesting
    }
}
export const setSummaryAccessAction = () => {
    return (dispatch) => {
        const access = Cookies.get('token');
        
        return axios.get(`${backendUrl}${summaryAccessPath}`, {
            headers: {
                'Authorization': `Bearer ${access}`
            }
        })
        .then(res => {
            dispatch({
                type: SET_SUMMARY_ACCESS,
                payload: res.data.hasAccess
            });
            console.log(res.data.hasAccess);
            return res.data.hasAccess;
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: SET_SUMMARY_ACCESS,
                payload: false
            });
            return false;
        });
    };
};