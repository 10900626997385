import MediaQuery from 'react-responsive';
import React, { Component } from 'react';
import ResultItem from './ResultItem';
import Export from './Export';
import Summary from './Summary';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

class ResultList extends Component{
    state = {
        showAllSummary: false
    };
    listClassName = {
        books: "container result-list direction-rtl padding-right-3p",
        ror: "container result-list padding-left-3p",
        alfazal: "container result-list direction-rtl padding-right-3p",
        engBooks: "container result-list padding-left-3p",
        holyQuran: 'container result-list direction-rtl padding-right-3p',
        arabicBooks: 'container result-list direction-rtl padding-right-3p',
        askislam: 'container result-list padding-left-3p',
        darsulQuran: 'container result-list padding-left-3p',
        albadar: 'container result-list padding-left-3p',
        alhakam: 'container result-list padding-left-3p',
        muslimSunrise: "container result-list padding-left-3p",
        all_indices: "container result-list padding-left-3p",
        fridaySermon: "container result-list padding-left-3p",
        akDiaries: "container result-list padding-left-3p",
        history:"container result-list padding-left-3p",
        germanBooks:"container result-list padding-left-3p",

    }
    

    
    render(){
        const { results, totalResults, tab ,summary,summaryAccess} = this.props;
        
        // console.log('summaryAccess in render:', this.props.summaryAccess);

        if (results.length) {
            return (
            <div>
                <span className="margin-top-5 display-block width-content margin-auto">Results Found: {totalResults === 10000 ? '10000+' : totalResults}</span>
                <MediaQuery minWidth={993}>
                <div className="export-summary-container" style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>
                        <Export />
                         {summaryAccess ? <Summary/> : null}
                    </div>
                </MediaQuery>
              
                <div className="snippet">
                {
                    this.props.summaryRequesting ? (
                        <div className="summary-container">
                            <p className="summary-item pulsing-text">✦ Generating summary...</p>
                        </div>
                    ) : summary && (
                        <div className="summary-container">
                            <h5 className="summary-title">AI Summary</h5>
                            <TransitionGroup>
                                {Object.entries(summary)
                                    .slice(0, this.state.showAllSummary ? undefined : 3)
                                    .map(([key, value], index) => (
                                        <CSSTransition
                                            key={key}
                                            timeout={500}
                                            classNames="fade"
                                            appear={true}
                                            style={{ transitionDelay: `${index * 100}ms` }}
                                        >
                                            
                                            <p className="summary-item">
                                                <strong>{key}.</strong> {value}
                                            </p>
                                           
                                        </CSSTransition>
                                    ))}
                            </TransitionGroup>
                            {summary && Object.keys(summary).length > 3 && (
                                <div className="show-more-btn-container">
                                <button 
                                    className="show-more-btn"
                                    onClick={() => this.setState({ showAllSummary: !this.state.showAllSummary })}
                                >
                                    {this.state.showAllSummary ? 'Show less ' : 'Show more '} 
                                    <i className="material-icons tiny">{this.state.showAllSummary ? 'arrow_upward' : 'arrow_downward'}</i>
                                </button>
                            </div>
                            )}
                        </div>
                    )
                }
                </div>

                <ol className={this.listClassName[tab]}>
                {
                    results.map(resultItem => {
                            return (
                                <li key={resultItem._id}> <ResultItem resultItem={resultItem}/> </li>
                                )
                            }
                        )
                }
                </ol>
            </div>
            )
        }
        else{
            return <div className="result-list"></div> //scroll function is looking for this class 
        }
    }
}

const mapStateToProps = state => ({
    summary: state.summary.summary,
    summaryRequesting: state.summary.requesting,
    summaryAccess: state.summary.summaryAccess
});

export default connect(mapStateToProps)(ResultList);
