const initState = {
    summary: null, // or summary: {}
    summaryAccess: false,
    requesting: false
};

const summaryReducer = (state = initState, action) => {
    switch(action.type) {
        case 'SET_SUMMARY':
            return {
                ...state,
                summary: action.payload // payload will be summaries object
            };
        case 'SET_SUMMARY_REQUESTING':
            return {
                ...state,
                requesting: action.requesting
            };
        case 'SET_SUMMARY_ACCESS':
            return {
                ...state,
                summaryAccess: action.payload
            };
        default:
            return state;
    }
}

export default summaryReducer;
