import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DisclaimerText from './disclaimerText';
import Bookmark from './Bookmark';




const styles = (theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 5,
    backgroundColor: "whitesmoke",
    marginRight: "8px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // overflowY: "visible"
  },
}))(MuiDialogContent);



function BaseModal({isOpen, content, title, titlePosition, disclaimer ,handleClose, contentStyle, imageUrl}) {

  return <>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth={'md'} aria-labelledby="customized-dialog-title">
          { titlePosition === "top" && 
            <DialogTitle className="customized-dialog-title" onClose={handleClose}>
                { title }
            </DialogTitle>
          }
          <DialogContent style={contentStyle}>
          {imageUrl ? (
                <div style={{ width: '100%', textAlign: 'center' }} >
                  <img loading="lazy" src={imageUrl} alt="Image" style={{ maxWidth: '100%', height: 'auto' }}/>
                </div>
              ) : (
                <>
                    {content}
                </>
              )}
          </DialogContent>

          <div>
      {titlePosition === "bottom" && (
        <div>
          <DialogTitle className="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
        </div>
      )}
    </div>
    <div>
      {disclaimer}
    </div>

         
      </Dialog>
    </>;
}


export default BaseModal;