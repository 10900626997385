import pdfReducer from './pdfReducer';
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import searchReducer from './searchReducer';
import resultsReducer from './resultsReducer';
import filtersReducer from './filtersReducer';
import messageReducer from './messageReducer';
import resultDetailsReducer from './resultDetailsReducer';
import langReducer from './langReducers';
import summaryReducer from './summaryReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    search: searchReducer,
    results: resultsReducer,
    resultDetails: resultDetailsReducer,
    message: messageReducer,
    filters: filtersReducer,
    pdfs: pdfReducer,
    lang:langReducer,
    summary: summaryReducer
})

export default rootReducer;
